<template>
  <Dropdown 
    v-bind="{
      ...$props,
      value: newValue,
      options,
      returnObject: true,
      itemText: 'CountryName',
      itemValue: 'CountryCode'
    }"
    @change="updateValue"
  >
  </Dropdown>
  
</template>
<script>
import Dropdown from '@/components/form/Dropdown.vue'

export default {
  name: "CountryDropdown",
  components: {
    Dropdown
  },
  data() {
    return {
      newValue: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: Boolean,
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String,
    defaultCountry: String
  },
  emits: [
    'change'
  ],
  methods: {
      updateValue(v) {
        if (v) {
          this.newValue = v;
        }
        else {
          this.newValue = null;
        }
        this.emitValue();
      },
      emitValue() {
        this.$emit('change', this.newValue)       
      },
  },
  computed: {
    options() {
      // return this.$store.getters['resource/getCountries'].map(o => { return {
      //   text: o.CountryName,
      //   value: o.CountryCode
      // }});
      return this.$store.getters['resource/getCountries'];
    }
  },
  watch: {
      options: {
        immediate: true,
        deep: true,
        handler() {
          setTimeout(() => {
            if (this.defaultCountry && !this.newValue && this.options.length) {
              this.newValue = this.options.find(o => o.CountryCode == this.defaultCountry);
              this.emitValue();
            }
          }, 100);
        }
      },
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            if (newVal && newVal.CountryCode) {
              this.newValue = newVal;
            }
          }
      }
  },
}
</script>