var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dropdown',_vm._b({on:{"change":_vm.updateValue}},'Dropdown',{
    ..._vm.$props,
    value: _vm.newValue,
    options: _vm.options,
    returnObject: true,
    itemText: 'CountryName',
    itemValue: 'CountryCode'
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }